<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-layout-gabi">
      <div style="">
        <!-- <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/invite001.png" style="width: 70%;margin-top: 12%;" />
        <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/invite001-1.png" style="width: 70%;margin-top: 12%;" /> -->
        <img src="@/assets/images/invite/gabii000.png" style="width: 100%;margin-bottom: 3%;" />
      </div>
      <div class="invite-footer" style="margin-top: 3%;">
        <div class="invite-code">
          {{$t('inviteFriend.i_code')}}：
          <div class="invite-code-text">
            <span @click="copyVal($event.target.innerText)">{{inviteCode}}</span>
            <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
              <img src="@/assets/common/copyIconL.png" style="width: 12px;margin-bottom: 10px;" />
            </span>
          </div>
        </div>

        <el-button v-loading="isLoadingBtn" element-loading-background="rgba(0, 0, 0, 0.5)" type="success" @click="goInvite()" style="border-radius: 30px;margin-bottom: 5%;background-color: rgba(244, 242, 34, 1);">
          <img src="@/assets/images/invite/GemsAndBlocksIcon.png" style="width: 30px;border-radius: 6px;float: left;" />
          <span style="font-size: 18px;margin-left: 10px;line-height: 30px;color: black;">
            Gems And Blocks
            <el-icon class="el-icon-right" style="margin-left: 8px;font-weight: bold;"></el-icon>
          </span>
        </el-button>
      </div>

      <el-dialog :visible.sync="isDownloadDialog" width="76%" center>
        <div style="text-align: center;">
          <div style="font-size: 20px;color: black;">
            {{$t('invite.i_havaDownloaded')}}
            <br />
            “Gems And Blocks”？
          </div>
          <el-button round @click="goDownload()" style="background-color: rgba(0, 111, 255, 1);color: white;margin-top: 30px;width: 60%;">
            {{$t('invite.i_goDownload')}}
          </el-button>
          <div @click="goApp()" style="text-decoration: underline;margin-top: 20px;color: rgba(161, 161, 161, 1);">
            {{$t('invite.i_downloaded')}}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        isLoadingBtn: false,
        webAppId: 6,
        inviteCode: "",
        isDownloadDialog: false
      };
    },
    methods: {
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      goInvite() {
        this.isLoadingBtn = true;
        this.$axios({
          url: "/webGift/invite",
          method: "get",
          params: {
            webAppId: this.webAppId
          }
        }).then((result) => {
          this.isLoadingBtn = false;
          if (result.data.code == 1) {
            this.isDownloadDialog = true;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      goDownload() {
        window.location.href =
          "https://oelesoft.s3.ap-southeast-1.amazonaws.com/GemsAndBlocks.html?referrer=" +
          this.inviteCode;
      },
      goApp() {
        window.location.href = "gemsblocks://webgf";
      }
    },
    created() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/invite",
        method: "get",
        params: {
          webAppId: this.webAppId
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
      this.inviteCode = this.$route.query.referrer;
    }
  };
</script>

<style>
  #invite-layout-gabi {
    width: 100%;
    height: 100%;
  }
  #invite-layout-gabi .invite-code {
    margin-bottom: 10%;
    font-size: 20px;
  }
  #invite-layout-gabi .invite-code-text {
    color: rgba(19, 100, 62, 1);
    display: inline;
  }
  #invite-layout-gabi .el-loading-mask {
    border-radius: 30px;
  }
  #invite-layout-gabi .el-dialog {
    border-radius: 15px !important;
  }
  #invite-layout-gabi .el-dialog__header {
    background: white !important;
    border-radius: 15px 15px 0 0 !important;
  }
  #invite-layout-gabi .el-dialog__body {
    background: white !important;
    border-radius: 0 0 15px 15px !important;
  }
</style>
