<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-layout">
      <div class="c-page-header">
        <!-- <el-page-header :title="$t('common.i_back')" :content="$t('convert.i_convert')" @back="$router.push('/index')" style="float: left"></el-page-header> -->
        <img src="@/assets/images/happy/returnIcon.png" @click="$router.push('/DreamParkMergeInvite')" style="width: 10px;float: left;" />
        <div style="float: right;">
          <img src="@/assets/common/logIcon.png" style="width: 20px;margin-right: 2px;vertical-align: middle;" />
          <router-link to="/convertLog" style="text-decoration: none;color: white;vertical-align: middle;">{{$t('convert.i_convertLog')}}</router-link>
        </div>
      </div>

      <div style="margin-top: 12%">{{$t('convert.i_convertT')}}</div>

      <div style="margin-top: 5%;">
        <img src="@/assets/common/moneyIcon.png" style="width: 30px;" />
        <span style="font-size: 42px;font-weight: bold;"> {{$store.state.goldNum}}</span>
      </div>

      <img src="@/assets/images/convertIcon.png" style="margin-top: 10%;width: 100px;" />

      <div style="margin-top: 14%;margin-bottom: 5%;margin-left: 5%;text-align: left;">
        {{$t('convert.i_choose')}}
      </div>
    </div>

    <div>
      <span v-for="giftCard in giftCards" v-bind:key="giftCard.cardId" >
        <router-link :to="{name: 'convertInfo', params: {id: giftCard.cardId}}">
          <img :src="giftCard.image" style="margin: 2%;width: 160px;border-radius: 8px;" />
        </router-link>
      </span>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ convert.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        checkDialog: false,
        giftCards: [],
        convert: {
          check: "",
          failMessage: "",
          appScheme: ""
        }
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.convert.check == "login") {
          window.location.href = this.convert.appScheme + "login";
        } else {
          window.location.href = this.convert.appScheme;
        }
      },
      getGiftCardList() {
        this.isLoading = true;
        this.$axios({
          url: "/webGift/convert",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.giftCards = result.data.data.giftCards;
            sessionStorage.setItem(
              "giftCards",
              JSON.stringify(result.data.data.giftCards)
            );
            sessionStorage.setItem(
              "goldNum",
              JSON.stringify(result.data.data.goldNum)
            );
            this.$store.state.goldNum = result.data.data.goldNum;
          } else if (result.data.code == -2) {
            this.convert.check = result.data.data.check;
            this.convert.failMessage = result.data.message;
            this.convert.appScheme = result.data.data.appScheme;
            this.checkDialog = true;
          }
        });
      }
    },
    created() {
      this.getGiftCardList();
    }
  };
</script>

<style>
  #convert-layout {
    width: 100%;
    height: 100%;
  }
  #convert-layout .c-page-header {
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
  }
</style>
